const LoginScreen = () => import(/* webpackChunkName: "auth" */ "@/components/Auth/LoginScreen");
const LogoutScreen = () => import(/* webpackChunkName: "auth" */ "@/components/Auth/LogoutScreen");
const SignupScreen = () => import(/* webpackChunkName: "auth" */ "@/components/Auth/SignupScreen");
const ForgotPasswordScreen = () => import(/* webpackChunkName: "auth" */ "@/components/PasswordReset/ForgotPasswordScreen");
const ResetPasswordScreen = () => import(/* webpackChunkName: "auth" */ "@/components/PasswordReset/ResetPasswordScreen");
const ItzaRedirect = () => import(/* webpackChunkName: "auth" */ "@/components/Auth/ItzaRedirect");

export default [
  {
    path: "/login",
    component: LoginScreen,
    name: "auth.login",
    meta: {
      title: "Log in",
      requiresAuth: false
    }
  },
  {
    path: "/logout",
    component: LogoutScreen,
    name: "auth.logout",
    meta: {
      title: "Log out",
      requiresAuth: false
    }
  },
  {
    path: "/signup",
    component: SignupScreen,
    name: "auth.signup",
    meta: {
      title: "Sign Up",
      requiresAuth: false
    }
  },
  {
    path: "/forgot-password",
    component: ForgotPasswordScreen,
    name: "auth.forgot-password",
    meta: {
      title: "Forgot Password",
      requiresAuth: false
    }
  },
  {
    path: "/reset-password/:token",
    component: ResetPasswordScreen,
    name: "auth.reset-password",
    meta: {
      title: "Reset Password",
      requiresAuth: false
    }
  },
  {
    path: "/itza-redirect",
    component: ItzaRedirect,
    name: "itza-redirect",
    meta: {
      title: "ItzaRedirect",
      requiresAuth: false
    }
  }
];
