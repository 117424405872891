const SettingsScreen = () => import(/* webpackChunkName: "settings" */ "@/components/Settings/SettingsScreen");

const GoorooPassScreen = () => import(/* webpackChunkName: "settings~gooroo-pass" */ "@/components/Settings/tabs/GoorooPass/GoorooPassScreen");
const MembershipScreen = () => import(/* webpackChunkName: "settings~membership" */ "@/components/Settings/tabs/Membership/MembershipScreen");
const AccountSettingsScreen = () => import(/* webpackChunkName: "settings~account" */ "@/components/Settings/tabs/AccountSettings/AccountSettingsScreen");

export default [
  {
    path: "/settings",
    component: SettingsScreen,
    children: [
      {
        path: "",
        name: "settings",
        redirect: {
          name: "settings.account"
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "account",
        name: "settings.account",
        meta: {
          title: "Account Settings",
          requiresAuth: true
        },
        component: AccountSettingsScreen
      },
      {
        path: "gooroo-pass",
        name: "settings.gooroo-pass",
        meta: {
          title: "Gooroo Pass",
          requiresAuth: true
        },
        component: GoorooPassScreen
      },
      {
        path: "membership",
        name: "settings.membership",
        meta: {
          title: "Membership",
          requiresAuth: true
        },
        component: MembershipScreen
      }
    ]
  }
];
