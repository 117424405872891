import Vue from "vue";
import Goo from "@gooroo-life/goo-ui-vue";
import VueApollo from "vue-apollo";
import VueGtm from "vue-gtm";

import { router } from "./router";
import { apolloProvider } from "./apollo";

import App from "@/components/App";

// Polyfills
import "focus-visible";

// CSS
import "@/css/theme.scss";

Vue.use(Goo);
Vue.use(VueApollo);

// Setup Google Tag Manager (only in production)
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
  enabled: process.env.VUE_APP_ENVIRONMENT === "production",
  vueRouter: router
});

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
const _ = new Vue({
  el: "#app",
  router,
  apolloProvider,
  render: h => h(App)
});
