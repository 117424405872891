import gql from "graphql-tag";

export const GET_TIMEZONES = gql`
  query GetTimeZones {
    timezones {
      identifier
      name
      country
    }
  }
`;

export const GET_PAYMENT_METHOD = gql`
  query GetPaymentMethod {
    paymentMethod {
      id
      brand
      lastDigits
      expMonth
      expYear
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    user: me {
      id
      firstName
      lastName
      email
      timezone
      phoneNumber
      itzaUserId
    }
  }
`;

export const GET_COURSE = gql`
  query GetCourse($id: ID!) {
    course: onlineCourse(id: $id) {
      id
      name
      image(variant: "medium")
      duration
      instructor {
        id
        firstName
        lastName
      }
    }
  }
`;
