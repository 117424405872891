import VueApollo from "vue-apollo";
import { HttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.VUE_APP_API_BASE}/graphql/public`,
    credentials: "include",
    headers: {
      "X-API-KEY": process.env.VUE_APP_API_KEY
    }
  }),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all"
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    }
  }
});

const coursesClient = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.VUE_APP_API_BASE}/graphql/courses`,
    credentials: "include",
    headers: {
      "X-API-KEY": process.env.VUE_APP_API_KEY
    }
  }),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all"
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    }
  }
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  clients: {
    courses: coursesClient
  }
});

export {
  apolloClient,
  apolloProvider
};
