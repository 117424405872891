import Vue from "vue";
import { apolloClient } from "@/apollo";
import { GET_USER } from "@/components/queries";

export default new Vue({
  data () {
    return {
      user: null,
      resolved: false,
      resolving: false
    };
  },
  computed: {
    isAuthenticated () {
      return this.user !== null;
    }
  },
  methods: {
    checkAuth (callback) {
      if (this.resolved) {
        callback(this.isAuthenticated);
      } else {
        this._resolve().then(() => { callback(this.isAuthenticated); });
      }
    },
    reset () {
      this.resolved = false;
      this.resolving = false;
    },
    _resolve () {
      this.resolving = true;

      return apolloClient.query({
        query: GET_USER
      }).then((result) => {
        this.user = result.data.user;
      }).catch(() => {
        // NoOp
      }).finally(() => {
        this.resolved = true;
        this.resolving = false;
      });
    }
  }
});
