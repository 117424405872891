import Vue from "vue";
import VueRouter from "vue-router";

import auth from "./auth";
import routes from "./routes";

Vue.use(VueRouter);

export const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  auth.checkAuth((authenticated) => {
    const shouldRedirectToLogin = to.meta.requiresAuth && !authenticated;

    if (shouldRedirectToLogin) {
      const { isNavigationFailure, NavigationFailureType } = VueRouter;

      const resolvedRoute = router.resolve(to);

      // eslint-disable-next-line no-unused-vars
      const _ = router.push({
        name: "auth.login",
        query: {
          next: resolvedRoute.href
        }
      }).catch((failure) => {
        if (!isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          throw failure;
        }
      });
    }
  });

  next();
});
