const GoorooPassCheckoutScreen = () => import(/* webpackChunkName: "gooroo-pass" */ "@/components/GoorooPass/GoorooPassCheckoutScreen");

export default [
  {
    path: "/gooroo-pass/checkout",
    component: GoorooPassCheckoutScreen,
    name: "gooroo-pass.checkout",
    meta: {
      title: "Gooroo Pass",
      requiresAuth: true
    }
  }
];
