import SettingsRoutes from "./settings";
import GoorooPassRoutes from "./gooroo-pass";
import AuthRoutes from "./auth";

const PageNotFound = () => import(/* webpackChunkName: "error" */ "@/components/Error/PageNotFound");

export default [
  {
    path: "/",
    name: "home",
    redirect: {
      name: "settings.account"
    },
    meta: {
      requiresAuth: false
    }
  },
  ...SettingsRoutes,
  ...GoorooPassRoutes,
  ...AuthRoutes,
  {
    path: "*",
    component: PageNotFound,
    meta: {
      requiresAuth: false
    }
  }
];
