<template>
  <div class="app">
    <div v-if="showLoader" class="app-loader">
      <GooSpinner />
    </div>
    <router-view v-else :user="user" />
  </div>
</template>

<script>
  import auth from "@/auth";

  export default {
    name: "App",
    computed: {
      user () {
        return auth.user;
      },
      showLoader () {
        return !auth.resolved || auth.resolving;
      }
    }
  };
</script>

<style lang="scss">
  .app {
    &-loader {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
</style>
